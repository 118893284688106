import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { actionsCreator } from "redux/actions/actionsCreator";
import GoogleAnalytics from 'appAnalytics/googleAnalytics';
import MixPanel from 'appAnalytics/mixPanel';
import { EVENT_CATEGORIES, EVENT_ACTIONS } from 'appAnalytics/eventCategories';
import { ACTION_TYPES, BRAND_ID_MAP, GARMENT_TYPE, GENDER, INSTRUCTIONS_TYPE } from 'utils/constants';
import { getDefaultMeasurement } from 'utils/babylonUtils';
import { skyboxConfig } from 'configs/SkyboxConfig';
import BabylonViewer from "containers/babylon";
import ToggleButton from "@mui/material/ToggleButton";
import { brandAPI, catalogAPI, proxyAPI, userServiceAPI } from "api";
import Loader from "components/Loader";
import HalfCard from "components/HalfCard";
import AvatarForm from "containers/avatarForm";
import { CameraActionBar } from 'components/CameraActionBar';
import Layout from "components/Layout";
import EditIcon from "assets/icons/editAvatar.svg";
import NoSkybox from "assets/icons/none.svg";
import { PencilIcon, ShoppingCartIcon, XIcon } from '@heroicons/react/outline';
import { ShoppingCartIcon as ShoppingCartIconFill } from '@heroicons/react/solid';
import { CAMERA_MODE, CAMERA_TYPE, HAIR_STYLES, TOOLTIP_TEXT } from 'configs/RendererConfig';
import Tooltip from 'components/Tooltip';
import Zoom from '@mui/material/Zoom';
import ReplaceAvatar from 'components/ReplaceAvatar';
import SkyboxListComponent from 'components/SkyboxListComponent';
import HairStylesList from 'components/HairStylesList';
import LoginAction from './LoginAction';
import "./AppModuleV2.scss";
import { FloatingAction } from 'containers/floatingAction/FloatingAction';
import FloatingButtonBar from 'components/FloatingButtonBar';
import Cart from 'components/Cart';
import Button from 'components/Button';
import { isIpad, isIphone, isTouchFriendly, toTitleCase } from 'utils';
import { Radio } from '@mui/material';
import { IFRAME_ACTIONS } from 'actions/IframeActions';
import Instructions from 'components/Instructions';
import HeatmapActions from 'components/HeatmapActions/HeatmapActions';
import Customize from 'containers/customize/Customize';
import BackgroundLight from 'assets/icons/BackgroundLight.svg';
import BackgroundDark from 'assets/icons/BackgroundDark.svg';
import FaceIcon from 'assets/icons/personalise/Face.svg';
import HairIcon from 'assets/icons/personalise/Hair.svg';
import HairFLight from 'assets/icons/personalise/HairF-Light.svg';
import HairFDark from 'assets/icons/personalise/HairF-Dark.svg';
import HairMLight from 'assets/icons/personalise/HairM-Light.svg';
import HairMDark from 'assets/icons/personalise/HairM-Dark.svg';
import MeasurementsIcon from 'assets/icons/personalise/Measurements.svg';
import HideOn from 'assets/icons/HideOn.svg';
import HideOff from 'assets/icons/HideOff.svg';
import CatalogIcon from 'assets/icons/hanger.png';
import { getBrandConfig } from 'api/brandAPI';
import CatalogList from 'components/CatalogList';
const reactEnv = process.env.REACT_APP_ENV;

const TermsAndConditionsURL = {
    'cava': "http://d1pu2jrxdqoaw5.cloudfront.net/cava/cava_terms.html",
    'beinghuman': "https://d1pu2jrxdqoaw5.cloudfront.net/beinghuman/beinghuman_terms.html",
}
const itemVariants = {
    open: {
        opacity: 1,
        y: 0,
        transition: { type: "spring", stiffness: 300, damping: 24 }
    },
    closed: { opacity: 0, y: 60, transition: { duration: 0.2 } }
};
const mapStateToProps = ({ avatarReducer = {} }) => ({
    faceUrl: get(avatarReducer, "userProfile.userAvatar.faceUrl", ""),
    textureUrl: get(avatarReducer, "userProfile.userAvatar.textureUrl", ""),
    hairId: get(avatarReducer, 'userProfile.userAvatar.hairId'),
    userProfile: get(avatarReducer, "userProfile", {}),
    defaultUserProfile: get(avatarReducer, "defaultUserProfile", {}),
    apiImage: get(avatarReducer, 'apiImage', ''),
    product: get(avatarReducer, "product", {}),
    loadedScene: get(avatarReducer, 'loadedScene', null),
    avatarAssets: get(avatarReducer, 'avatarAssets', null),
    isAnonymous: get(avatarReducer, 'isAnonymous', true),
    oldUserId: get(avatarReducer, 'oldUserId', ''),
    instructionCheckList: get(avatarReducer, 'instructionCheckList', []),
    sizeRecommendations: get(avatarReducer, 'sizeRecommendations', {}),
    firstTimeCreation: get(avatarReducer, 'firstTimeCreation', false),
    isDefaultAvatar: get(avatarReducer, "isDefaultAvatar", false),
    cartDetails: get(avatarReducer, "cartDetails", {}),
    catalogList: get(avatarReducer, "catalogList", []),
});
const AppModuleV2 = (props) => {
    const location = useLocation();
    const toolTipTimerId = useRef(null);
    const firstTimeScreenTimer = useRef(null);
    let {
        instructionCheckList,
        faceUrl,
        apiImage,
        userProfile = {},
        product = {},
        loadedScene = null,
        avatarAssets = null,
        isAnonymous = true,
        oldUserId = '',
        sizeRecommendations = {},
        firstTimeCreation = false,
        token = '',
        isDefaultAvatar,
        cartDetails,
        catalogList,
    } = useSelector(
        mapStateToProps
    );
    const { garment_type, variations, variants = [], counterVariants = [], unisex, hideProductCounterPart = false, brand } = product;
    const { gender } = userProfile;
    let { product_path, product_counter_part } = unisex?.[gender] || product;
    const { maleHairId, femaleHairId = 'Original' } = userProfile.userAvatar || {};
    const dispatch = useDispatch();
    let selectedHairStyleRef = useRef({});
    const [heatmapLoading, setHeatmapLoading] = useState(false);
    const [addToCart, setAddToCart] = useState(false);
    const [heatmapSize, changeHeatmapSize] = useState('');
    const [cameraMode, setCameraMode] = useState(CAMERA_MODE.Body);
    const [loading, setLoader] = useState(true);
    const [bodyVisualiserloader, setBodyVisualiserLoader] = useState(false);
    const [sceneLoading, setSceneLoading] = useState(true);
    const [error, setError] = useState("");
    const [showAvatarForm, setShowAvatarForm] = useState(false);
    const [drapeClothes, setdrapeClothes] = useState(false);
    const [showSignInForm, setShowSignInForm] = useState(false);
    const [showBodyVisualiser, setShowBodyVisualiser] = useState(false);
    const [showHeatmap, setShowHeatmap] = useState(false);
    const [selectedSize, setSelectedSize] = useState('');
    const [showEnvironments, toggleEnvironment] = useState(false);
    const [showVariations, setShowVariations] = useState(false);
    const [showHairSelector, toggleHairSelector] = useState(false);
    const [selectedHairStyle, setSelectedHairStyle] = useState({});
    const [showReplaceAvatar, setShowReplaceAvatar] = useState(false);
    const [replaceAvatarLoader, setReplaceAvatarLoader] = useState(false);
    const [normalisedfitIndicationValueTop, setNormalisedfitIndicationValueTop] = useState('');
    const [normalisedfitIndicationValueBottom, setNormalisedfitIndicationValueBottom] = useState('');
    const [skyboxList, setSkyboxList] = useState([]);
    const [selectedSkybox, setSelectedSkybox] = useState(null);
    const [toolTip, setToolTip] = useState({});
    const [fitScoreText, setFitScoreText] = useState('');
    const [highLightedTab, setHighlightedTab] = useState('');
    const [showPersonaliseOptions, setShowPersonaliseOptions] = useState(true);
    const [selectedActionType, setSelectedActionType] = useState(ACTION_TYPES.NONE);
    const [showInstructions, setShowInstructions] = useState('');
    const [invokeInstructionHandler, setInvokeInstructionHandler] = useState(false);
    const [firstTimeScreen, setFirstTimeScreen] = useState(false);
    const [isFirstTimeUser, setIsFirstTimeUser] = useState(false);
    const [showFittingRoomButton, setShowFittingRoomButton] = useState(false);
    const [showHeatmapInfo, toggleHeatMapInfo] = useState(false);
    const [selectedProductPart, setSelectedProductPart] = useState(product_path);
    const [isCameraActionOpened, setCameraActionOpened] = useState(false);
    const [viewMode, toggleViewMode] = useState(false);
    const [showCatalogList, setShowCatalogList] = useState(false);
    const [cartLoaderObj, setCartLoaderObj] = useState({ 'product': false, 'counterProduct': false, 'allItems': false });
    const showVariationAction = Object.keys(variations || {}).length > 0;
    const eventCategory = !isDefaultAvatar ? EVENT_CATEGORIES.PERSONAL_AVATAR : faceUrl ? EVENT_CATEGORIES.PERSONAL_AVATAR_FACE: EVENT_CATEGORIES.DEFAULT_AVATAR;
    const hairStyleKeys = Object.keys(unisex?.[gender] ? HAIR_STYLES[gender] : HAIR_STYLES[product.gender] || {}) || [];
    const { clothSize, fit = {}, fitInsights = {} } =
        sizeRecommendations || {};
    const { normalFit, tightFit, looseFit } = fit[selectedProductPart] || {};
    // const avatarImage = !isDefaultAvatar ? apiImage: (product?.gender !== userProfile?.gender) ?  
    const getSkyboxConfig = (brand) => {
        if (brand && skyboxConfig[brand]) {
            return skyboxConfig[brand];
        }
        const routePathList = location.pathname.split('/');
        if (routePathList.length > 1 && skyboxConfig[routePathList[1]]) {
            return skyboxConfig[routePathList[1]];
        }
        return skyboxConfig['default'];
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }
    const prevProduct = usePrevious(product);

    const replaceProduct = async (product, plus1, userDetail) => {
        loadedScene.getEngine().displayLoadingUI();
        const { product_path, product_counter_part, shoes, brand, avatar } = product;
        const { height, weight, gender, userAvatar, defaultAvatar } = userDetail;
        const { chest, hip, waist, highHip, bodyType, measurementType } = userAvatar || {};
        let payload = {
            height,
            weight,
            chest,
            hip,
            highHip,
            waist,
            gender,
            unit: "cm",
            product: product || {},
            selectedSize: plus1 ? 'plus1' : '',
            defaultAvatar,
            selectedBodyType: bodyType,
            measurementType,
        };
        const sizeRecommendations = await proxyAPI.getSizeRecommendations(payload);
        dispatch(actionsCreator.SET_SIZE_RECOMMENDATIONS(get(sizeRecommendations, 'data', {})));
        loadedScene.meshes.forEach(mesh => {
            if (mesh.name) {
                if (mesh.name.includes('clothAsset') ||
                    mesh.name.includes('avatarBody') ||
                    mesh.name.includes('shoes') ||
                    mesh.name.includes('head') ||
                    mesh.name.includes('face') ||
                    mesh.name.includes('hair') ||
                    mesh.name.includes('hairClone') ||
                    mesh.name.includes('CustomHair')
                ) {
                    mesh.setEnabled(false);
                }
            }
        });

        const assetsAlreadyInScene = (product, nextProductMesh, nextCounterProductMesh, nextProductShoesMesh) => {
            const { product_path, product_counter_part, shoes } = product;
            const pathCheck = product_path ? nextProductMesh : true;
            const counterPartCheck = product_counter_part ? nextCounterProductMesh : true;
            const shoescheck = shoes ? nextProductShoesMesh : true;
            return pathCheck && counterPartCheck && shoescheck;
        }
        let avatarUrls = avatarAssets;
        const plus1Name = plus1 ? '_plus1' : '';
        let bodyMesh = loadedScene.getMeshByName(`avatarBody_${product.gender}_${product.avatar}${plus1Name}`);
        let headMesh = loadedScene.getMeshByName(`head_${product.gender}_${product.avatar}`);
        let hairMesh = loadedScene.getMeshByName(`hair_${product.gender}_${product.avatar}`);
        let hairId = product.gender === 'female' ? femaleHairId : maleHairId;
        if (selectedHairStyle) {
            hairId = get(selectedHairStyle, 'name', '');
        }
        const customHair = loadedScene.getMeshByName(`CustomHair_${hairId}_${product.gender}_${product.avatar}`)
        let hairCloneMesh = loadedScene.getMeshByName(`hairClone_${product.gender}_${product.avatar}`);
        let faceMesh = loadedScene.getMeshByName(`face_${product.gender}_${product.avatar}`);
        if (bodyMesh && prevProduct.gender === product.gender && prevProduct.brand === product.brand) {
            bodyMesh.setEnabled(true);
        } else {
            bodyMesh && bodyMesh.dispose();
            bodyMesh = null;
            const avatarUrlResponse = await proxyAPI.getAvatarUrl(payload);
            avatarUrls = avatarUrlResponse.data;
        }
        let nextProductMesh = loadedScene.getMeshByName(`clothAsset_${product_path}_${product.avatar}${plus1Name}`);
        let nextCounterProductMesh = loadedScene.getMeshByName(`clothAsset_${product_counter_part}_${product.avatar}${plus1Name}`);
        let nextProductShoesMesh = loadedScene.getMeshByName(`shoes_${product.shoes ? `${product.shoes}_` : ''}${product.avatar}${plus1Name}`);
        if (assetsAlreadyInScene(product, nextProductMesh, nextCounterProductMesh, nextProductShoesMesh) && headMesh && bodyMesh && faceMesh) {
            nextProductMesh && nextProductMesh.setEnabled(true);
            nextCounterProductMesh && nextCounterProductMesh.setEnabled(true);
            nextProductShoesMesh && nextProductShoesMesh.setEnabled(true);
            nextProductShoesMesh && nextProductShoesMesh.getChildMeshes(false, c => c.setEnabled(true));
            headMesh.setEnabled(true);
            faceMesh.setEnabled(true);
            loadedScene.loadingCounter--;
            loadedScene.loadingCounter < 1 && loadedScene.getEngine().hideLoadingUI();
            if (customHair) {
                customHair.setEnabled(true);
                hairMesh && hairMesh.setEnabled(false);
                hairCloneMesh && hairCloneMesh.setEnabled(false);
            } else if (selectedHairStyle && selectedHairStyle.gender === product.gender) {
                setSelectedHairStyle({ ...selectedHairStyle });
            } else {
                hairMesh && hairMesh.setEnabled(true);
                hairCloneMesh && hairCloneMesh.setEnabled(true);
            }
        } else {
            const clothPayload = {
                bodyIdentifier: get(avatarUrls, "bodyIdentifier"),
                rIdentifier: get(avatarUrls, "rIdentifier"),
                gender: get(userDetail, "userAvatar.gender"),
                measurmentType: get(userDetail, "userAvatar.measurementType"),
                selectedBodyType: get(userDetail, "bodyType"),
                product_path,
                product_counter_part,
                brand,
                avatar,
                shoes,
                selectedSize: plus1 ? 'plus1' : '',
                defaultAvatar,
            };
            const clothUrlResponse = await proxyAPI.getClothUrl(clothPayload);
            let updatedAvatarAssets = { ...avatarUrls, assets: { ...get(clothUrlResponse, "data", {}) }, }
            dispatch(actionsCreator.SET_AVATAR_ASSETS(updatedAvatarAssets));
            setLoader(false);
            setdrapeClothes(true);
        }
    }

    const fetchProductDetails = async (id) => {
        try {
            setError("");
            const response = await catalogAPI.getProductDetails({ id });
            const product = get(response, 'data.response', {});
            dispatch(actionsCreator.SET_PRODUCT_DATA({ ...product, brand: product.company_id }));
        } catch (e) {
            console.log(e);
            setError("Could not find the product");
            dispatch(actionsCreator.SET_PRODUCT_DATA({}));
        }
    }


    const getBrandConfig = async (brand) => {
        try {
            const response = await brandAPI.getBrandConfig({ brandId: BRAND_ID_MAP?.[brand] || BRAND_ID_MAP['default'] });
            const relatedBrands = response?.data?.response?.related_brands || {};
            dispatch(actionsCreator.SET_RELATED_BRANDS(relatedBrands));
        } catch (e) {
            console.error(e);
            dispatch(actionsCreator.SET_RELATED_BRANDS({}));
        }
    }

    const recieveMessage = event => {
        const message = event.data.message;
        const { PRODUCT_OPENED, PRODUCT_CLOSED, CART_DETAILS, RESET_CART_LOADER, SEND_PRODUCT_DATA } = IFRAME_ACTIONS
        switch (message) {
            case PRODUCT_OPENED: {
                dispatch(actionsCreator.ENABLE_ANALYTICS(true));
                const buttonName = EVENT_ACTIONS[event.data.value] || EVENT_ACTIONS.TRY_IN_3D;
                MixPanel.buttonClicked(EVENT_CATEGORIES.VIEW_3D, buttonName, {}, true);
                return;
            }
            case PRODUCT_CLOSED:
                MixPanel.buttonClicked(EVENT_CATEGORIES.CLOSE_3D, EVENT_ACTIONS.PRODUCT_CLOSED);
                dispatch(actionsCreator.ENABLE_ANALYTICS(false));
                return;
            case CART_DETAILS:
                const cartDetails = event.data.value;
                dispatch(actionsCreator.SET_CART_DETAILS(cartDetails));
                return;
            case RESET_CART_LOADER:
                const { type, message } = event.data.value;
                if (type) {
                    setCartLoaderObj({ ...cartLoaderObj, [type]: false, message });
                }
                return;
            case SEND_PRODUCT_DATA:
                const productConfig = event.data.value;
                dispatch(actionsCreator.ENABLE_ANALYTICS(true));
                const { productData: product, catalogList = [], button = "" } = productConfig || {};
                if (button) {
                    const buttonName = EVENT_ACTIONS[button] || EVENT_ACTIONS.TRY_IN_3D;
                    MixPanel.buttonClicked(EVENT_CATEGORIES.VIEW_3D, buttonName, {}, true);
                };
                const { company_id, sku } = product || {};
                if (company_id && sku) {
                    fetchProductDetails(`${company_id}${sku}`);
                    const skyboxList = getSkyboxConfig(company_id);
                    setSkyboxList(skyboxList);
                    if (isEmpty(selectedSkybox)) {
                        setSelectedSkybox(skyboxList[0]);
                    } else {
                        let index = skyboxList.findIndex(s => s.id === selectedSkybox.id && s.thumbnailImage === selectedSkybox.thumbnailImage);
                        if (index === -1) {
                            index = 0;
                        }
                        setSelectedSkybox(skyboxList[index]);
                    }
                } else {
                    if (prevProduct?.id !== product?.id) {
                        dispatch(actionsCreator.SET_PRODUCT_DATA(product));
                        dispatch(actionsCreator.SET_CATALOG_LIST(catalogList));
                        if (prevProduct?.brand !== product?.brand) {
                            getBrandConfig();
                        }
                        const skyboxList = getSkyboxConfig(get(product, 'brand', ''));
                        setSkyboxList(skyboxList);
                        if (isEmpty(selectedSkybox)) {
                            setSelectedSkybox(skyboxList[0]);
                        } else {
                            let index = skyboxList.findIndex(s => s.id === selectedSkybox.id && s.thumbnailImage === selectedSkybox.thumbnailImage);
                            if (index === -1) {
                                index = 0;
                            }
                            setSelectedSkybox(skyboxList[index]);
                        }
                        handleCloseToolTip();
                    };
                }
                return;
            default:
                return;
        }
    };

    useEffect(() => {
        window.parent.postMessage({ message: IFRAME_ACTIONS.APP_MOUNTED }, "*");
    }, []);

    useEffect(() => {
        selectedHairStyleRef.current = selectedHairStyle;
    }, [selectedHairStyle]);

    useEffect(() => {
        window.addEventListener("message", recieveMessage, false);
        setShowReplaceAvatar(!!oldUserId);
        return () => {
            window.removeEventListener("message", recieveMessage);
        };
    }, [recieveMessage]);


    useEffect(() => {
        setShowSignInForm(false);
        if (!isEmpty(product)) {
            GoogleAnalytics.buttonClicked(EVENT_CATEGORIES["PRODUCT_OPENED"], product.product_path);
            setSelectedSize('');
            onAvatarCreated(product, false);
        }
        if (!isEmpty(userProfile) && !isEmpty(product)) {
            let { gender } = userProfile;
            if (!gender) {
                setSelectedHairStyle('Original');
            } else {
                const { gender: selectedHairGender } = selectedHairStyle;
                const selectedHair = selectedHairGender === gender ?
                    selectedHairStyle :
                    gender === 'female' ?
                        HAIR_STYLES[gender][femaleHairId] : HAIR_STYLES[gender][maleHairId];
                setSelectedHairStyle(selectedHair ? selectedHair : HAIR_STYLES[gender][gender === 'female' ? 'Original' : 'Original']);
            }
        }
    }, [userProfile, product]);

    useEffect(() => {
        if (!sceneLoading && !loading && !isEmpty(userProfile)) {
            const isDifferentGender = get(product, 'gender') !== 'unisex' && get(userProfile, 'gender') !== get(product, 'gender');
            const isFirstTimeUser = isDifferentGender || !userProfile?.gender || userProfile?.defaultAvatar || !userProfile?.userAvatar?.chest;
            // const isFirstTimeUser = true;
            setIsFirstTimeUser(isFirstTimeUser);
            if (isFirstTimeUser) {
                dispatch(actionsCreator.SET_AVATAR_IMAGE(''));
                clearTimeout(firstTimeScreenTimer.current);
                setCameraMode(CAMERA_MODE[CAMERA_TYPE.WELCOME_SCREEN]);
                firstTimeScreenTimer.current = setTimeout(() => {
                    setFirstTimeScreen(isFirstTimeUser);
                    setShowFittingRoomButton(isFirstTimeUser);
                }, 2000);
            } else {
                setFirstTimeScreen(isFirstTimeUser);
                setShowFittingRoomButton(isFirstTimeUser);
            }
        }
        return () => {
            clearTimeout(firstTimeScreenTimer.current);
        }
    }, [sceneLoading, loading, userProfile])

    const onAvatarSave = (product) => {
        setShowAvatarForm(false);
        setSelectedSize('');
        onAvatarCreated(product, false);
    }

    const onAvatarCreated = (product, plus1) => {
        const { gender: productGender } = product;
        setShowBodyVisualiser(false);
        setHighlightedTab('');
        setShowHeatmap(false);
        const { gender, userAvatar } = userProfile;
        if (gender && !!userAvatar?.chest && (gender === productGender || productGender === 'unisex')) {
            setError("");
            setProduct(product, plus1, userProfile);
            dispatch(actionsCreator.IS_DEFAULT_AVATAR(false));
            setIsFirstTimeUser(false);
        } else {
            let defaultGender = productGender;
            if (productGender === 'unisex') {
                defaultGender = 'male';
            }
            const defaultUserProfile = { ...userProfile, ...getDefaultMeasurement(defaultGender) };
            dispatch(actionsCreator.SET_DEFAULT_AVATAR_DATA({ defaultUserProfile: defaultUserProfile }));
            dispatch(actionsCreator.IS_DEFAULT_AVATAR(true));
            setProduct(product, plus1, defaultUserProfile);
        }
    }

    const setProduct = (product, plus1, userDetail) => {
        // if (!isEmpty(loadedScene)) {
        //     if (prevProduct && prevProduct.product_path !== product.product_path) {
        //         loadedScene.loadingCounter++;
        //         replaceProduct(product, plus1, userDetail)
        //     }
        // } else {
        if (prevProduct.product_path !== product.product_path) {
            if(!isEmpty(loadedScene)) {
                loadedScene.dispose();
                loadedScene = null;
                dispatch(actionsCreator.SET_LOADED_SCENE(null));
            }
            getAvatar(product, userDetail, plus1);
        }
        // }
    }

    const getAvatar = async (product, userDetails, plus1) => {
        try {
            setLoader(true);
            let { height, weight, gender, userAvatar } = userDetails;
            const { chest, hip, waist, highHip, bodyType, measurementType } = userAvatar || {};
            let { product_path, product_counter_part, brand, avatar, shoes, unisex } = product;
            if (unisex?.[gender]) {
                product_path = unisex?.[gender].product_path;
                product_counter_part = unisex?.[gender].product_counter_part;
                avatar = unisex?.[gender].avatar;
                shoes = unisex?.[gender].shoes;
            }
            let payload = {
                height,
                weight,
                chest,
                hip,
                highHip,
                waist,
                gender,
                unit: "cm",
                selectedBodyType: bodyType,
                measurementType,
                product: { ...product, product_path, product_counter_part, brand, avatar, shoes },
                selectedSize: plus1 ? 'plus1' : ''
            };
            await getAvatarAssets(payload, userDetails, plus1);
            setLoader(false);
        } catch (e) {
            setLoader(false);
            console.error(e);
        }
    };

    const getAvatarAssets = async (payload, userDetails, plus1) => {
        const { defaultAvatar } = userDetails;
        const { product: productFromParam } = payload;
        let selectedProduct = productFromParam;
        let { product_path, product_counter_part, brand, avatar, shoes } = selectedProduct;
        // const userDetail = !isEmpty(userDetails) ? userDetails : userProfile;
        const avatarUrlResponse = await proxyAPI.getAvatarUrl({ ...payload, defaultAvatar });
        const clothPayload = {
            bodyIdentifier: get(avatarUrlResponse, "data.bodyIdentifier"),
            rIdentifier: get(avatarUrlResponse, "data.rIdentifier"),
            gender: get(userDetails, "gender"),
            measurmentType: get(userDetails, "userAvatar.measurementType"),
            selectedBodyType: get(userDetails, "userAvatar.bodyType"),
            product_path,
            product_counter_part,
            brand,
            avatar,
            shoes,
            selectedSize: plus1 ? 'plus1' : '',
            defaultAvatar
        };
        const clothUrlResponse = await proxyAPI.getClothUrl(clothPayload);
        dispatch(actionsCreator.SET_AVATAR_ASSETS({
            ...get(avatarUrlResponse, "data", {}),
            assets: { ...get(clothUrlResponse, "data", {}) },
        }));
        const {
            rIdentifier,
            bIdentifier,
            fit,
            clothSize,
            chestMessage,
            waistMessage,
            scalingFactor,
            position,
            fitInsights,
        } = get(avatarUrlResponse, 'data', {});
        const sizeData = {
            rIdentifier,
            bIdentifier,
            fit,
            clothSize,
            chestMessage,
            waistMessage,
            scalingFactor,
            position,
            fitInsights,
        }
        const selectedproductPart = !!(fit?.[product_path]?.normalFit) ? product_path : fit?.[product_counter_part]?.normalFit ? product_counter_part : "";
        setSelectedProductPart(selectedproductPart);
        dispatch(actionsCreator.SET_SIZE_RECOMMENDATIONS(sizeData));
    };
    const bodyVisualiserHandler = (actionType) => {
        setSelectedActionType(actionType);
        if (actionType === ACTION_TYPES.DEFAULT || actionType === ACTION_TYPES.SELFIE) {
            const avatarGender = product.gender !== 'unisex' ? product.gender : gender;
            setCameraMode(CAMERA_MODE[avatarGender === GENDER.FEMALE ? CAMERA_TYPE.FACE_SELECTION_FEMALE : CAMERA_TYPE.FACE_SELECTION]);
        }
        setShowBodyVisualiser(true);
        // setShowPersonaliseOptions(false);
        GoogleAnalytics.buttonClicked(eventCategory, EVENT_ACTIONS["PERSONALISE_SHOW"], { actionType })
        MixPanel.buttonClicked(eventCategory, EVENT_ACTIONS["PERSONALISE_SHOW"], { actionType })
    }
    const togglePersonaliseOptions = () => {
        setShowPersonaliseOptions(!showPersonaliseOptions);
        if (instructionCheckList.indexOf(INSTRUCTIONS_TYPE.PERSONALISE) === -1) {
            dispatch(actionsCreator.SET_INSTRUCTION_LIST([...instructionCheckList, INSTRUCTIONS_TYPE.PERSONALISE]));
        }
        GoogleAnalytics.buttonClicked(eventCategory, EVENT_ACTIONS["PERSONALISE_SHOW"]);
        MixPanel.buttonClicked(eventCategory, showPersonaliseOptions ? EVENT_ACTIONS.PERSONALISE_OPTION_CLOSED : EVENT_ACTIONS.PERSONALISE_OPTION_OPENED);
    }
    const backHandler = () => {
        setShowAvatarForm(false);
        setShowBodyVisualiser(false);
        setHighlightedTab('');
        setShowHeatmap(false);
        setCameraMode(isFirstTimeUser ? CAMERA_MODE.WelcomeScreen : CAMERA_MODE.Body);
        GoogleAnalytics.buttonClicked(eventCategory, EVENT_ACTIONS["PERSONALISE_HIDE"]);
        MixPanel.buttonClicked(eventCategory, EVENT_ACTIONS["PERSONALISE_HIDE"]);
    }

    const toggleHeatMap = (selectedProductPart) => {
        if (!sceneLoading && !heatmapLoading) {
            if (selectedProductPart) {
                setSelectedProductPart(selectedProductPart);
            }
            setShowHeatmap(!showHeatmap);
            if (!showHeatmap) {
                toggleHeatMapInfo(false);
                disableHeatmapTooltip();
                MixPanel.buttonClicked(eventCategory, EVENT_ACTIONS["HEATMAP_SHOW"], { selectedProductPart });
            } else {
                MixPanel.buttonClicked(eventCategory, EVENT_ACTIONS["HEATMAP_HIDE"], { selectedProductPart });
            }
            setHeatmapLoading(true);
            changeHeatmapSize('');
        }
    }

    const confirmHandler = async () => {
        setShowReplaceAvatar(false);
        try {
            const payload = { anonymousUserId: oldUserId };
            const response = await userServiceAPI.transerAvatar(payload);
            if (response && response.status === 200) {
                let userDataResponse = await userServiceAPI.getAvatarData();
                let userData = userDataResponse.data;
                let imageUrl = get(userData, 'userProfile.userAvatar.imageUrl', '');
                loadedScene && loadedScene.dispose();
                dispatch(actionsCreator.SET_LOADED_SCENE(null));
                dispatch(actionsCreator.SET_AVATAR_DATA(userData));
                dispatch(actionsCreator.SET_AVATAR_IMAGE(imageUrl));
                dispatch(actionsCreator.SET_API_IMAGE(imageUrl));
            }
            setReplaceAvatarLoader(false);
        } catch (e) {
            console.log(e);
            setReplaceAvatarLoader(false);
        }
    }

    const discardHandler = () => {
        setShowReplaceAvatar(false);
        dispatch(actionsCreator.SET_OLD_USER_ID(''));
        setLoader(false);
    }
    const toggleBackground = (value) => {
        if (value) {
            toggleHairSelector(false);
            setCameraActionOpened(false);
        }
        toggleEnvironment(value);
    }

    const toggleVariations = () => {
        setShowVariations(!showVariations);
    };

    const updateHairs = async (hairGender) => {
        try {
            const { height, weight, gender, userAvatar } = userProfile;
            const { chest, hip, waist, highHip } = userAvatar || {};
            const hairName = get(selectedHairStyleRef, 'current.name', '');
            const defaultSelection = hairGender === 'male' ? 'Original' : 'Original';
            const hairId = hairName !== defaultSelection ? hairName : defaultSelection;
            let updatedMaleHairId = maleHairId;
            let updatedFemaleHairId = femaleHairId;
            if (hairGender === 'female') {
                updatedFemaleHairId = hairId;
            } else {
                updatedMaleHairId = hairId;
            }
            const payload = {
                maleHairId: updatedMaleHairId,
                femaleHairId: updatedFemaleHairId,
                gender,
                height,
                weight,
                chest,
                hip,
                highHip,
                waist
            }
            await userServiceAPI.updateUserData({ payload });
            const updatedUserData = {
                userProfile: {
                    ...userProfile,
                    userAvatar: {
                        ...userProfile.userAvatar,
                        maleHairId: updatedMaleHairId,
                        femaleHairId: updatedFemaleHairId,
                    }
                }
            }
            dispatch(actionsCreator.SET_AVATAR_DATA(updatedUserData));
        } catch (e) {
            console.log(e);
        }
    }

    const toggleHairs = (value) => {
        if (value) {
            toggleEnvironment(false);
        }
        toggleHairSelector(value);
        const { gender, name } = selectedHairStyleRef.current;
        const selectedHairId = gender === 'female' ? femaleHairId : maleHairId;
        const defaultSelection = gender === 'female' ? 'Original' : 'Original';
        if ((!value && selectedHairId !== name) || (name === defaultSelection && !selectedHairId)) {
            updateHairs(gender);
        }
    }

    const handleCloseToolTip = () => {
        setToolTip({});
    }

    const handleOpenToolTip = useCallback((key) => {
        const { gender } = userProfile;
        const { gender: productGender } = product;
        let tooltipText = TOOLTIP_TEXT[key];
        if ((key === 'Personalise' || key === 'DefaultPersonalise') && gender !== productGender) {
            tooltipText = productGender ? TOOLTIP_TEXT['DefaultPersonalise'].replace('{gender}', productGender) : '';
        }
        const updatedToolTipObj = {};
        updatedToolTipObj[key] = tooltipText;
        setToolTip(updatedToolTipObj);
    }, [product, userProfile])

    const toolTipHandler = useCallback((product) => {
        if (selectedActionType === ACTION_TYPES.DEFAULT && !sceneLoading && !loading) {
            clearTimeout(toolTipTimerId.current);
            let toolTipToShow = '';
            let hideToolTip = true;
            if (instructionCheckList.indexOf(INSTRUCTIONS_TYPE.HEATMAP) === -1) {
                toolTipToShow = 'CheckFit';
                hideToolTip = false;
                toggleHeatMapInfo(true);
            } else if (instructionCheckList.indexOf(INSTRUCTIONS_TYPE.PERSONALISE) === -1) {
                toolTipToShow = 'Personalise';
                hideToolTip = false;
            }
            if (toolTipToShow) {
                toolTipTimerId.current = setTimeout(() => {
                    handleOpenToolTip(toolTipToShow);
                }, 2000);
            }
            if (hideToolTip) {
                setTimeout(() => {
                    handleCloseToolTip();
                }, 6000);
            }
        }
    }, [handleOpenToolTip, instructionCheckList, loading, sceneLoading, selectedActionType])

    const highLightTab = (tab) => {
        setHighlightedTab(tab);
    }

    const updateAvatarHandler = (userData) => {
        if(!userData?.userAvatar?.chest && userData?.gender) {
            userData = getDefaultMeasurement(userData?.gender);
        }
        getAvatar(product, userData, plus1);
        // if (loadedScene) {
        //     console.log(loadedScene);
        //     const { product_path, product_counter_part, shoes } = product;
        //     const plus1Name = plus1 ? '_plus1' : '';
        //     let nextProductMesh = loadedScene.getMeshByName(`clothAsset_${product_path}_${product.avatar}${plus1Name}`);
        //     let nextCounterProductMesh = loadedScene.getMeshByName(`clothAsset_${product_counter_part}_${product.avatar}${plus1Name}`);
        //     loadedScene.meshes.forEach(mesh => {
        //         console.log("meshes", mesh);
        //         if (mesh.material) {
        //             mesh.material.wireframe = true;
        //         }
        //     });
        // }
    }

    const getSelectedHeatmapSize = (productId) => {
        const fitting = fit[productId] || {};
        switch (heatmapSize) {
            case 'minus1': {
                return fitting['tightFit'];
            }
            case 'plus1': {
                return fitting['looseFit'];
            }
            default: {
                return fitting['normalFit'];
            }
        }
    }

    const disableHeatmapTooltip = useCallback(() => {
        handleCloseToolTip();
        if (instructionCheckList.indexOf(INSTRUCTIONS_TYPE.HEATMAP) === -1) {
            dispatch(actionsCreator.SET_INSTRUCTION_LIST([...instructionCheckList, INSTRUCTIONS_TYPE.HEATMAP]));
        }
    }, [instructionCheckList]);

    const disablePersonaliseToolTip = useCallback(() => {
        handleCloseToolTip();
        if (instructionCheckList.indexOf(INSTRUCTIONS_TYPE.PERSONALISE) === -1) {
            dispatch(actionsCreator.SET_INSTRUCTION_LIST([...instructionCheckList, INSTRUCTIONS_TYPE.PERSONALISE]));
        }
        setSelectedActionType(ACTION_TYPES.NONE);
        dispatch(actionsCreator.SET_FIRST_TIME_CREATION(false));
    }, [instructionCheckList]);

    const showSizePlus = get(userProfile, 'gender') === 'female' && clothSize && clothSize === 'medium' && get(props, 'selectedProduct.brand', '') === 'adidas';
    const plus1 = showSizePlus && selectedSize && selectedSize === looseFit;
    const textColorStyle = selectedSkybox && selectedSkybox.type === 'dark' ? { color: '#fff' } : { color: '#5c5c5c' };
    const showLoader = loading || replaceAvatarLoader;
    const hairIconDark = unisex?.[gender] ? (gender === 'female' ? HairFDark : HairMDark) : product.gender === 'female' ? HairFDark : HairMDark;
    const hairIconLight = unisex?.[gender] ? (gender === 'female' ? HairFLight : HairMLight) : product.gender === 'female' ? HairFLight : HairMLight;
    const otherGarmentType = garment_type === GARMENT_TYPE.TOP ? GARMENT_TYPE.BOTTOM : GARMENT_TYPE.TOP;
    const showBoth = product_path && product_counter_part && fit[product_path]?.normalFit && fit[product_counter_part]?.normalFit;
    const selectedHeatmapSize = getSelectedHeatmapSize(selectedProductPart);
    const showPersonaliseOverlay = firstTimeCreation && toolTip['Personalise'] && instructionCheckList.indexOf(INSTRUCTIONS_TYPE.PERSONALISE) === -1;
    const showCheckFitOverlay = firstTimeCreation && toolTip['CheckFit'] && instructionCheckList.indexOf(INSTRUCTIONS_TYPE.HEATMAP) === -1;
    const HTMLRecommendationToolTip = useCallback(() => {
        return <div className="Dopplr_RecommendedTooltip">
            {/* <div className='Dopplr_RecommendedTooltip__Title'>RECOMMENDED SIZE</div> */}
            <div className='Dopplr_RecommendedTooltip__Content'> These sizes will be your perfect fit!</div>
        </div>
    }, [])
    const HTMLPersonaliseToolTip = () => {
        return <div className="Dopplr_RecommendedTooltip">
            <div className='Dopplr_RecommendedTooltip__Title'>PERSONALISE AVATAR</div>
            <div className='Dopplr_RecommendedTooltip__Content'>Click here to personalise your avatar</div>
        </div>
    }

    const closeActions = () => {
        toggleEnvironment(false);
        // setShowPersonaliseOptions(false);
        setCameraActionOpened(false);
    }

    const closeIframe = () => {
        window.parent.postMessage({ message: IFRAME_ACTIONS.CLOSE_IFRAME }, "*");
    }

    const normalisedFitIndicationValue = selectedProductPart === product_path ?
        garment_type === GARMENT_TYPE.TOP ? normalisedfitIndicationValueTop : normalisedfitIndicationValueBottom :
        otherGarmentType === GARMENT_TYPE.TOP ? normalisedfitIndicationValueTop : normalisedfitIndicationValueBottom;
    const showAddToCartButton = (variants.length > 0 || counterVariants.length > 0) && false;

    return <Layout>
        {showLoader && !error && <Loader className={"TopLoader"} brand={get(props, 'selectedProduct.brand', '')} />}
        {error && <div className="Babylon__Error">{error}</div>}
        {false && <LoginAction className="Dopplr_LoginAction" setReplaceAvatarLoader={setReplaceAvatarLoader} />}
        {!showBodyVisualiser && <div className={`Dopplr_CloseButton ${viewMode ? 'hide' : ''}`} onClick={() => showHeatmap ? toggleHeatMap() : closeIframe()}>
            <XIcon style={{ width: '60%' }} />
        </div>}
        {!showLoader && !error && <>
            <BabylonViewer
                highLightedTab={highLightedTab}
                setFitScoreText={setFitScoreText}
                handleCloseToolTip={handleCloseToolTip}
                toolTipHandler={toolTipHandler}
                hair={selectedHairStyle}
                setHeatmapLoading={setHeatmapLoading}
                cameraMode={cameraMode}
                setCameraMode={setCameraMode}
                sceneLoading={sceneLoading}
                showBodyVisualiser={showBodyVisualiser}
                setSceneLoading={setSceneLoading}
                drapeClothes={drapeClothes}
                setDrapeClothes={setdrapeClothes}
                error={error}
                selectedSkybox={selectedSkybox}
                plus1={plus1}
                showHeatmap={showHeatmap}
                changeHeatmapSize={changeHeatmapSize}
                heatmapSize={heatmapSize}
                selectedProductPart={selectedProductPart}
                setSelectedProductPart={setSelectedProductPart}
                setNormalisedfitIndicationValueTop={setNormalisedfitIndicationValueTop}
                setNormalisedfitIndicationValueBottom={setNormalisedfitIndicationValueBottom}
                showVariations={showVariations}
                closeActions={closeActions}
                showAddToCart={addToCart}
                isFirstTimeUser={isFirstTimeUser}
            />

        </>}
        {!showLoader && !sceneLoading && !showBodyVisualiser && <>
            {!showHeatmap && <>
                {/* {showPersonaliseOverlay && <div className='Dopplr_FirstTimeUser__Overlay' onClick={disablePersonaliseToolTip}></div>} */}
                <motion.div
                    animate={showPersonaliseOptions ? "open" : "closed"}
                    style={{
                        position: 'absolute',
                        top: '0.5em',
                        right: viewMode ? '-5.5em' : '0.5em',
                        zIndex: true ? 2 : 1,
                        transition: 'all 0.3s ease-in-out',
                        height: showPersonaliseOptions ? 'auto' : '0',
                    }}
                >
                    {/* {!showHeatmap && <Tooltip
                        TransitionComponent={Zoom}
                        // title={toolTip['Personalise'] || toolTip['DefaultPersonalise']}
                        title={HTMLPersonaliseToolTip()}
                        arrow
                        placement='right'
                        enterDelay={1500} leaveDelay={200}
                        open={!!(toolTip['Personalise'] || toolTip['DefaultPersonalise'] || false)}
                        onClose={() => !showPersonaliseOverlay && handleCloseToolTip()}
                        onOpen={() => handleOpenToolTip('Personalise')}
                    >
                        <motion.div
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.9 }}
                            initial={{ x: '-50%' }}
                            className='Dopplr_PersonaliseV2'

                        // onClick={togglePersonaliseOptions}
                        >
                            <img src={apiImage || EditIcon} alt=""
                                style={apiImage ? { width: '100%', objectFit: 'cover', height: '100%' } : { width: '70%', height: '70%', objectFit: 'cover' }} />
                        </motion.div>
                    </Tooltip>} */}
                    <motion.div
                        className='Dopplr_PersonaliseV2__ActionBar'
                        initial={false}
                        variants={{
                            open: {
                                clipPath: 'inset(0% 0% 0% 0% round 10em)',
                                transition: {
                                    type: "spring",
                                    bounce: 0,
                                    duration: 0.3,
                                    delayChildren: 0.1,
                                    staggerChildren: 0.05
                                }
                            },
                            closed: {
                                clipPath: 'inset(6% 41% 90% 41% round 10em)',
                                transition: {
                                    type: "spring",
                                    bounce: 0,
                                    duration: 0.3
                                }
                            }
                        }}
                    >
                        <motion.div
                            initial={{ opacity: 0, scale: 0.5, x: '100%', transition: { duration: 0.3 } }}
                            animate={{ opacity: 1, scale: 1, x: '0', transition: { duration: 0.3 } }}
                            className='Dopplr_PersonaliseOptions'
                        >
                            <motion.div
                                whileHover={{ scale: 1.02 }}
                                whileTap={{ scale: 0.97 }}
                                variants={itemVariants}
                                className='Dopplr_PersonaliseOptions__FloatingAction'
                                onClick={() => {
                                    MixPanel.buttonClicked(eventCategory, EVENT_ACTIONS.FACE_EDIT);
                                    bodyVisualiserHandler(ACTION_TYPES.SELFIE)
                                }}>
                                <motion.div
                                    className={`Dopplr_PersonaliseOptions__FloatingButton ${showBodyVisualiser ? 'selected' : ''}`}>
                                    {//Add once sresht came back to toggle option
                                    }
                                    {/* <img
                                        src={FaceIcon}
                                        alt="" style={{ width: '100%', height: '100%' }} /> */}
                                    <img src={apiImage || EditIcon} alt=""
                                        style={apiImage ? { width: '100%', objectFit: 'cover', height: '100%' } : { width: '70%', height: '70%', objectFit: 'cover' }} />
                                </motion.div>
                                <div className='Dopplr_PersonaliseOptions__Text'>Face</div>
                            </motion.div>
                            <motion.div
                                whileHover={{ scale: 1.02 }}
                                whileTap={{ scale: 0.97 }}
                                variants={itemVariants}
                                className='Dopplr_PersonaliseOptions__FloatingAction'
                                onClick={() => {
                                    MixPanel.buttonClicked(eventCategory, EVENT_ACTIONS.MEASUREMENTS_EDIT);
                                    bodyVisualiserHandler(ACTION_TYPES.MEASUREMENTS)
                                }}
                            >
                                <motion.div
                                    className={`Dopplr_PersonaliseOptions__FloatingButton ${showBodyVisualiser ? 'selected' : ''}`}>
                                    <img
                                        src={MeasurementsIcon}
                                        style={{ width: '100%' }} alt="Measurements" />
                                </motion.div>
                                <div className='Dopplr_PersonaliseOptions__Text'>Measurements</div>
                            </motion.div>
                            {hairStyleKeys && hairStyleKeys.length > 0 &&
                                <motion.div
                                    whileHover={{ scale: 1.02 }}
                                    whileTap={{ scale: 0.97 }}
                                    variants={itemVariants}
                                    className='Dopplr_PersonaliseOptions__FloatingAction'
                                    onClick={() => {
                                        MixPanel.buttonClicked(eventCategory, !showHairSelector ? EVENT_ACTIONS.SHOW_HAIRSTYLES : EVENT_ACTIONS.HIDE_HAIRSTYLES);
                                        toggleHairs(!showHairSelector)
                                    }}
                                >
                                    <motion.div
                                        className={`Dopplr_PersonaliseOptions__FloatingButton ${showHairSelector && !selectedHairStyle.thumbnailImage ? 'selected' : ''}`}>
                                        <img
                                            src={HairIcon}
                                            style={{ width: '100%' }} alt="Measurements" />
                                        {/* <img src={selectedHairStyle.thumbnailImage ? selectedHairStyle.thumbnailImage : showHairSelector ? hairIconLight : hairIconDark}
                                            style={selectedHairStyle.thumbnailImage ? { width: '100%', height: '100%', objectFit: 'contain' } : { width: '60%' }} alt="Hair" /> */}
                                    </motion.div>
                                    <div className='Dopplr_PersonaliseOptions__Text'>Hair</div>
                                </motion.div>}
                        </motion.div>
                    </motion.div>
                </motion.div>
                {(!(isIphone || isIpad) || (reactEnv === 'qa')) && <motion.div
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.97 }}
                    className={`Dopplr_Backgrounds ${showCatalogList ? 'Dark' : ''} ${viewMode ? 'hide' : ''}`}
                    onClick={() => {
                        MixPanel.buttonClicked(eventCategory, !showCatalogList ? EVENT_ACTIONS.CATALOGLIST_OPENED : EVENT_ACTIONS.CATALOGLIST_CLOSED);
                        setShowCatalogList(!showCatalogList)
                    }}
                    style={{ bottom: '18.5em' }}
                    animate={showCatalogList ? "open" : "closed"}
                >
                    <img src={CatalogIcon} alt="Background" style={{ width: '70%', height: '70%' }} />
                </motion.div>}
                <motion.div
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.97 }}
                    className={`Dopplr_Backgrounds ${showEnvironments ? 'Dark' : ''} ${viewMode ? 'hide' : ''}`}
                    onClick={() => {
                        MixPanel.buttonClicked(eventCategory, !showEnvironments ? EVENT_ACTIONS.SHOW_BACKGROUND : EVENT_ACTIONS.HIDE_BACKGROUND);
                        toggleBackground(!showEnvironments)
                    }}
                    animate={showEnvironments ? "open" : "closed"}
                >
                    <img src={showEnvironments ? BackgroundLight : BackgroundDark} alt="Background" />
                </motion.div>
                <motion.div
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.97 }}
                    className={`Dopplr_HideButton ${viewMode ? 'Dark' : ''}`}
                    onClick={() => {
                        closeActions();
                        MixPanel.buttonClicked(eventCategory, !viewMode ? EVENT_ACTIONS.VIEW_MODE_ON : EVENT_ACTIONS.VIEW_MODE_OFF);
                        toggleViewMode(!viewMode);
                    }}
                >
                    <img src={viewMode ? HideOn : HideOff} alt="" />
                </motion.div>
                {/* {showAddToCartButton && <motion.div
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.97 }}
                    className='Dopplr_AddToCart'
                    variants={{
                        open: { rotate: [0, 360], transition: { duration: 0.5 } },
                        closed: { rotate: [360, 0], transition: { duration: 0.5 } }
                    }}
                    onClick={() => {
                        MixPanel.buttonClicked(faceUrl && !isDefaultAvatar ? EVENT_CATEGORIES["PERSONAL_AVATAR"] : EVENT_CATEGORIES["DEFAULT_AVATAR"], EVENT_ACTIONS[addToCart ? "CART_CLOSED" : "CART_OPENED"]);
                        setAddToCart(!addToCart);
                    }}
                    animate={addToCart ? "open" : "closed"}
                >
                    {addToCart ?
                        <ShoppingCartIconFill className='Dopplr_AddToCart__FloatingCartIcon' /> :
                        <ShoppingCartIcon className='Dopplr_AddToCart__FloatingCartIcon' />}
                </motion.div>} */}
                {showVariationAction && <motion.div
                    className={`Dopplr_Variations`}
                >
                    <Radio
                        checked={!showVariations}
                        onChange={() => toggleVariations(false)}
                        value={false}
                        size={'small'}
                        name="radio-buttons"
                        sx={{
                            '&.Mui-checked': {
                                color: 'black',
                            }
                        }}
                    />
                    <Radio
                        checked={showVariations}
                        onChange={() => toggleVariations(true)}
                        value={true}
                        size={'small'}
                        name="radio-buttons"
                        sx={{
                            '&.Mui-checked': {
                                color: 'black',
                            }
                        }}
                    />
                </motion.div>}
                {!showHeatmap && <CameraActionBar
                    setCameraMode={setCameraMode}
                    closeActions={closeActions}
                    viewMode={viewMode}
                    cameraMode={cameraMode}
                    eventCategory={eventCategory}
                    isCameraActionOpened={isCameraActionOpened}
                    setCameraActionOpened={setCameraActionOpened} />}
            </>}
            {!showHeatmap &&
                <>
                    {showCheckFitOverlay && <div className='Dopplr_FirstTimeUser__Overlay' onClick={disableHeatmapTooltip}></div>}
                    <Tooltip
                        TransitionComponent={Zoom}
                        title={HTMLRecommendationToolTip()}
                        arrow
                        enterDelay={1000} leaveDelay={200}
                        placement='bottom-start'
                        open={!!(toolTip['CheckFit'] || false)}
                        onClose={() => !showCheckFitOverlay && handleCloseToolTip()}
                        onOpen={() => handleOpenToolTip('CheckFit')}
                    >
                        <motion.div
                            // initial={{ opacity: 0, scale: 0.5, x: '0', transition: { duration: 0.3 } }}
                            // animate={{ zIndex: toolTip['CheckFit'] ? 2 : 1, opacity: 1, scale: 1, x: '0', transition: { duration: 0.3 } }}
                            className={`Dopplr_PrimaryActionsV2 ${viewMode ? 'hide' : ''}`}
                        >
                        {isFirstTimeUser ?
                            <div className='Dopplr_PrimaryActionsV2__FloatingActionContainer'>
                            <div className='Dopplr_PrimaryActionsV2__FloatingAction__Title'>What's My Size ?</div>
                            <div className='Dopplr_FeedMeasurements'>
                                <Button type='black-white' onClick={() => {
                                    MixPanel.buttonClicked(eventCategory, EVENT_ACTIONS.FEED_MEASUREMENTS);
                                    bodyVisualiserHandler(ACTION_TYPES.MEASUREMENTS);
                                }}>Feed Measurements</Button>
                            </div>
                        </div>
                            :
                            <div className='Dopplr_PrimaryActionsV2__FloatingActionContainer'>
                                <div className='Dopplr_PrimaryActionsV2__FloatingAction__Title'>Recommended</div>
                                {product_path && <FloatingAction
                                    className='Dopplr_PrimaryActionsV2__FloatingAction'
                                    clickHandler={() => fit[product_path]?.normalFit && toggleHeatMap(product_path)}
                                    isActive={showHeatmap}
                                    text={toTitleCase(garment_type)}
                                    buttonClassName={`Dopplr_PrimaryActionsV2__FloatingButton ${showHeatmap ? 'selected' : ''}`}
                                >
                                    {fit[product_path]?.normalFit || 'NA'}
                                </FloatingAction>}
                                {product_counter_part && !hideProductCounterPart && <FloatingAction
                                    className='Dopplr_PrimaryActionsV2__FloatingAction'
                                    clickHandler={() => fit[product_counter_part]?.normalFit && toggleHeatMap(product_counter_part)}
                                    isActive={showHeatmap}
                                    text={toTitleCase(otherGarmentType)}
                                    buttonClassName={`Dopplr_PrimaryActionsV2__FloatingButton ${showHeatmap ? 'selected' : ''}`}
                                >
                                    {fit[product_counter_part]?.normalFit || 'NA'}
                                </FloatingAction>}
                            </div>
                            }

                        </motion.div>
                    </Tooltip>
                </>}
        </>
        }
        {!showLoader && !sceneLoading && !showBodyVisualiser && <>
            {(isFirstTimeUser || firstTimeScreen) ? <>
                <div className='Dopplr_FirstTimeUser__WelcomeText'>
                    <div className='Dopplr_FirstTimeUser__WelcomeText__Title'>Welcome!</div>
                    {/* <div className='Dopplr_FirstTimeUser__WelcomeText__Text'>This is your Virtual Dressing Room</div> */}
                </div>
                <FloatingButtonBar style={{ background: 'none' }}>
                    {TermsAndConditionsURL?.[brand] && <div className="Dopplr__PrivacyContent">
                        <div>By proceeding, I agree to the <a target="_blank" rel="noreferrer" href={TermsAndConditionsURL[brand]}>Terms & Conditions.</a></div>
                    </div>}
                    <Button type='white-black' onClick={() => {
                        bodyVisualiserHandler(ACTION_TYPES.DEFAULT);
                        MixPanel.buttonClicked(eventCategory, EVENT_ACTIONS.CUSTOMIZE);
                        setFirstTimeScreen(false);
                    }
                    }>
                        <div className='FloatingButton__Content'>
                            <PencilIcon className="CustomiseIcon" />
                            <span>Customize</span>
                        </div>
                    </Button>
                    <Button type='black-white' onClick={() => {
                        MixPanel.buttonClicked(eventCategory, EVENT_ACTIONS[addToCart ? "CART_CLOSED" : "CART_OPENED"]);
                        setAddToCart(!addToCart);
                    }}>
                        Add To Cart
                    </Button>
                </FloatingButtonBar>
            </> :
                <FloatingButtonBar style={{ background: 'none' }} customClass={viewMode ? 'hide' : ''}>
                    <Button type='white-black' disabled={!selectedProductPart || !fit?.[product_path]?.normalFit} onClick={() => toggleHeatMap(product_path)
                    }>
                        <div className='FloatingButton__Content'>
                            {/* <PencilIcon className="CustomiseIcon" /> */}
                            <span>View Fit</span>
                        </div>
                    </Button>
                    {true && <Button type='black-white' onClick={() => {
                        MixPanel.buttonClicked(eventCategory, EVENT_ACTIONS[addToCart ? "CART_CLOSED" : "CART_OPENED"]);
                        setAddToCart(!addToCart);
                    }}>
                        Add To Cart
                    </Button>}
                </FloatingButtonBar>}
        </>}
        {
            showBodyVisualiser && <HalfCard
                fullHeight={true}
                visible={showBodyVisualiser}
                key={'personalise'}
                onClose={backHandler}
                showCloseButton={true}
                title="Personalise"
                wrapperClassName="BodyVisualiserWrapper"
                closeOnBackgroundClick={!bodyVisualiserloader}
            >
                <Customize
                    backHandler={backHandler}
                    actionType={selectedActionType}
                    updateAvatar={updateAvatarHandler}
                    isFirstTimeUser={isFirstTimeUser}
                    setCameraMode={setCameraMode}
                />
                {/* <BodyVisualiser
                    invokeInstructionHandler={invokeInstructionHandler}
                    setInvokeInstructionHandler={setInvokeInstructionHandler}
                    setShowInstructions={setShowInstructions}
                    showInstructions={showInstructions}
                    updateAvatar={updateAvatarHandler}
                    isFirstTimeUser={isFirstTimeUser}
                    setCameraMode={setCameraMode}
                    actionType={selectedActionType} backHandler={backHandler} loader={bodyVisualiserloader} setLoader={setBodyVisualiserLoader} highLightTab={highLightTab} /> */}
            </HalfCard>
        }
        {showHeatmap && <HeatmapActions
            showHeatmap={showHeatmap}
            toggleHeatMap={toggleHeatMap}
            showBoth={showBoth}
            hideProductCounterPart={hideProductCounterPart}
            showHeatmapInfo={showHeatmapInfo}
            selectedProductPart={selectedProductPart}
            setSelectedProductPart={setSelectedProductPart}
            changeHeatmapSize={changeHeatmapSize}
            product_path={product_path}
            product_counter_part={product_counter_part}
            garment_type={garment_type}
            otherGarmentType={otherGarmentType}
            normalisedFitIndicationValue={normalisedFitIndicationValue}
            textColorStyle={textColorStyle}
            selectedHeatmapSize={selectedHeatmapSize}
            fitInsights={fitInsights}
            fit={fit}
            brand={brand}
            userData={userProfile?.userAvatar || {}}
            toggleHeatMapInfo={toggleHeatMapInfo}
            eventCategory={eventCategory}
        />
        }
        {showAvatarForm && <AvatarForm backHandler={backHandler} onSuccess={onAvatarSave} />}
        <ReplaceAvatar showReplaceAvatar={showReplaceAvatar} confirmHandler={confirmHandler} discardHandler={discardHandler} />
        {
            !error && !sceneLoading && <>
                <SkyboxListComponent
                    showEnvironments={showEnvironments}
                    toggleBackground={toggleBackground}
                    skyboxList={skyboxList}
                    selectedSkybox={selectedSkybox}
                    setSelectedSkybox={setSelectedSkybox}
                    eventCategory={eventCategory}
                />
                <HairStylesList
                    product={product}
                    hairStyleKeys={hairStyleKeys}
                    gender={unisex?.[gender] ? gender : product.gender}
                    showHairSelector={showHairSelector}
                    toggleHairs={toggleHairs}
                    selectedHairStyle={selectedHairStyle}
                    setSelectedHairStyle={setSelectedHairStyle}
                    eventCategory={eventCategory}
                />
            </>
        }
        {
            showInstructions && <Instructions
                showInstructions={showInstructions}
                setShowInstructions={setShowInstructions}
                setInvokeInstructionHandler={setInvokeInstructionHandler}
            />
        }
        {
            addToCart && <Cart
                addToCart={addToCart}
                setAddToCart={(val) => {
                    MixPanel.buttonClicked(eventCategory, EVENT_ACTIONS[val ? "CART_OPENED" : "CART_CLOSED"]);
                    setAddToCart(val);
                }}
                isFirstTimeUser={isFirstTimeUser}
                cartDetails={cartDetails}
                fit={fit}
                product={product}
                cartLoaderObj={cartLoaderObj}
                setCartLoaderObj={setCartLoaderObj}
                eventCategory={eventCategory}
            />}
        {
            showCatalogList && <CatalogList
                showCatalogList={showCatalogList}
                eventCategory={eventCategory}
                setShowCatalogList={(val) => {
                    MixPanel.buttonClicked(eventCategory, EVENT_ACTIONS.CATALOGLIST_CLOSED);
                    setShowCatalogList(val);
                }}
                catalogList={catalogList}
                selectedProduct={product}
                loading={showLoader || sceneLoading}
            />
        }
    </Layout >
};

export default AppModuleV2;