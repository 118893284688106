import React, { Suspense } from "react";
import { Router, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import { AuthProvider } from "contexts/AuthContext";
import Personalise from "containers/personalise";
import DummyProduct, { DummyProductCopy } from "containers/dummyProduct";
import AdidasProducts from "containers/adidasProducts";
// import {
//   ReactCanvasSceneComponentCopy
// } from "containers/babylon";
import AvatarForm from "containers/avatarForm";
import Login from "containers/login";
import SelfieModule1 from "containers/selfieModule/SelfieModule1";
import SelfieModule from "containers/selfieModule";
import OrientationInfo from "containers/deviceOrientation"
import SandBox from "containers/sandbox";
import AssetViewer from "containers/assetViewer";
import Products from "containers/Products";
import AppModuleV2 from "containers/babylon/AppModuleV2";

// eslint-disable-next-line react/display-name
const LazyLoadComponent = RouteComponent => props => (
  <Suspense fallback={<div>Loading ...</div>}>
    <RouteComponent {...props} />
  </Suspense>
);
const LazyLoadComponentWithAuth = (RouteComponent, anonymousLogin, localRoute = true) => props => {
  return <AuthProvider anonymousLogin={anonymousLogin} localRoute={localRoute}>
    <Suspense fallback={<div>Loading ...</div>}>
      <RouteComponent {...props} />
    </Suspense>
  </AuthProvider>
}

/**
  * This component's sole purpose is routes management
  * Components being called using "loginHOC" are authenticated components.
    They'll prompt login screen first if the user is not logged in.
  * Components being rpassed directly (without loginHOC) are public pages.
    Login will not be prompted. If in case some part/flow of the component requires
    user-authentication, It can use "LoginWrapper" or "loginHOC" internally.
*/

const history = createBrowserHistory();

const Routes = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route
          exact
          path="/demo1"
          component={LazyLoadComponentWithAuth(() => (
            <AdidasProducts showAll={true} />
          ))}
        />
        <Route
          exact
          path="/adidas"
          component={LazyLoadComponentWithAuth(() => (
            <AdidasProducts />
          ))}
        />
        {/* <Route
          exact
          path="/abfrl"
          component={LazyLoadComponentWithAuth(() => (
            <AdidasProducts />
          ))}
        />
        <Route
          exact
          path="/abfrlm"
          component={LazyLoadComponentWithAuth(() => (
            <AdidasProducts />
          ))}
        />
        <Route
          exact
          path="/abfrlf"
          component={LazyLoadComponentWithAuth(() => (
            <AdidasProducts />
          ))}
        /> */}
        <Route
          exact
          path="/demo2"
          component={LazyLoadComponentWithAuth(() => (
            <DummyProductCopy />
          ))}
        />
        <Route
          exact
          path="/dopplr-avatar/:domain"
          component={LazyLoadComponentWithAuth(() => (
            <AppModuleV2 anonymousLogin={true} />
          ), true, false)}
        />
        <Route
          exact
          path="/dopplr-plugin/:domain"
          component={LazyLoadComponentWithAuth(() => (
            <AppModuleV2 anonymousLogin={true} />
          ), true, true)}
        />
        {/* <Route
          exact
          path="/dopplr-avatar-old"
          component={LazyLoadComponentWithAuth(() => (
            <ReactCanvasSceneComponentCopy />
          ))}
        /> */}
        <Route
          exact
          path="/personalise"
          component={LazyLoadComponentWithAuth(() => (
            <Personalise />
          ))}
        />
        <Route
          exact
          path="/avatar-form"
          component={LazyLoadComponentWithAuth(() => (
            <AvatarForm />
          ))}
        />
        <Route
          exact
          path="/login"
          component={LazyLoadComponentWithAuth(() => (
            <Login />
          ))}
        />
        <Route
          path="/adidas2"
          component={LazyLoadComponentWithAuth(() => (
            <AdidasProducts />
          ))}
        />
        <Route
          exact
          path="/lightbox"
          component={LazyLoadComponentWithAuth(() => (
            <SandBox uploadMode={true} />
          ))}
        />
        <Route
          exact
          path="/sandbox"
          component={LazyLoadComponentWithAuth(() => (
            <SandBox />
          ))}
        />
        <Route
          exact
          path="/demo/:brandId"
          component={LazyLoadComponentWithAuth(() => (
            <AssetViewer />
          ))}
        />
        <Route
          exact
          path="/oasis"
          component={LazyLoadComponentWithAuth(() => (
            <AdidasProducts />
          ))}
        />
        <Route
          exact
          path="/valentino"
          component={LazyLoadComponentWithAuth(() => (
            <AdidasProducts />
          ))}
        />
        {/* <Route
          exact
          path="/SAN"
          component={LazyLoadComponentWithAuth(() => (
            <Products />
          ))}
        /> */}
        <Route
          exact
          path="/ccclub"
          component={LazyLoadComponentWithAuth(() => (
            <AdidasProducts />
          ))}
        />
        <Route
          exact
          path="/cava"
          component={LazyLoadComponentWithAuth(() => (
            <AdidasProducts bypassLogin={false} />
          ), false)}
        />
        <Route
          exact
          path="/test"
          component={LazyLoadComponentWithAuth(() => (
            <AdidasProducts bypassLogin={false} />
          ), false)}
        />
        <Route
          exact
          path="/beinghuman"
          component={LazyLoadComponentWithAuth(() => (
            <AdidasProducts bypassLogin={false} />
          ), false)}
        />
        <Route
          exact
          path="/rarerabbit"
          component={LazyLoadComponentWithAuth(() => (
            <AdidasProducts bypassLogin={false} />
          ), false)}
        />
        <Route
          exact
          path="/BHuman"
          component={LazyLoadComponentWithAuth(() => (
            <AdidasProducts bypassLogin={false} />
          ), false)}
        />
        <Route
          exact
          path="/beinghumans"
          component={LazyLoadComponentWithAuth(() => (
            <AdidasProducts bypassLogin={false} />
          ), false)}
        />
        <Route
          exact
          path="/gyro"
          component={LazyLoadComponentWithAuth(() => (
            <OrientationInfo bypassLogin={false} />
          ), false)}
        />
        <Route
          exact
          path="/virgio"
          component={LazyLoadComponentWithAuth(() => (
            <AdidasProducts bypassLogin={false} />
          ), false)}
        />
        <Route
          exact
          path="/forevernew"
          component={LazyLoadComponentWithAuth(() => (
            <AdidasProducts bypassLogin={false} />
          ), false)}
        />
        <Route
          exact
          path="/iff"
          component={LazyLoadComponentWithAuth(() => (
            <AdidasProducts bypassLogin={false} />
          ), false)}
        />
        <Route
          exact
          path="/nicobar"
          component={LazyLoadComponentWithAuth(() => (
            <AdidasProducts />
          ))}
        />
        <Route
          exact
          path="/selfie"
          component={LazyLoadComponentWithAuth(() => (
            <SelfieModule bypassLogin={false} />
          ), false)}
        />
        <Route
          exact
          path="/selfie1"
          component={LazyLoadComponentWithAuth(() => (
            <SelfieModule1 bypassLogin={false} />
          ), false)}
        />
        <Route
          exact
          path="/nicobar-test"
          component={LazyLoadComponentWithAuth(() => (
            <AdidasProducts />
          ))}
        />
        <Route
          exact
          path="/puma"
          component={LazyLoadComponentWithAuth(() => (
            <AdidasProducts bypassLogin={false} />
          ), false)}
        />
        <Route
          exact
          path="/puma-female"
          component={LazyLoadComponentWithAuth(() => (
            <AdidasProducts />
          ))}
        />
        <Route
          exact
          path="/selfie/:token/:jobId"
          component={LazyLoadComponent(() => (
            <SelfieModule />
          ))}
        />
      </Switch>
    </Router >
  );
};

export default Routes;
