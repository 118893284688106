import React from 'react';
import { useDeviceOrientation } from './useDeviceOrientation';
import OrientationSwitcher from './OrientationSwitcher';
import AllowFrame from './AllowFrame';
import AllowFrameTF from './AllowFrameTF';

const OrientationInfo = ({ onFlagChange }) => {
  const { orientation, requestAccess, revokeAccess } = useDeviceOrientation();

  const onToggle = (toggleState) => {
    // eslint-disable-next-line no-unused-vars
    const result = toggleState ? requestAccess() : revokeAccess();
  };

  const gyroscopePointerPosition = (value) => {
    const result = (value * 360) / 180;
    let position = result;

    if (result < 0) {
      position = 0;
    }

    if (result > 360) {
      position = 360;
      return position;
    }
    return position;
  }
  const getGyroValue = ()=>{
    return orientation?gyroscopePointerPosition(orientation.beta):180
  }
  const isGryoRange = (value)=>{
    if(value<200&&value>155){
      onFlagChange(true)
      return false;
    }
    else {
      onFlagChange(false)
      return true;
    }
  }
  return (
    <div style={{zIndex:50}}> 
      {isGryoRange(getGyroValue()) && <AllowFrame
        info={false}
        isLastPhoto={true}   
        gyroscopePosition={getGyroValue()}
      />}
      <OrientationSwitcher
        onToggle={onToggle}
        labelOff="Show orientation angles"
        labelOn="Hide orientation angles"
      />
    </div>
  );
};

export default OrientationInfo;

