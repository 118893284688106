import React, { useState } from "react"

import { useDeviceOrientation } from "./useDeviceOrientation"
import Toggle from "./Toggle"
// import Alert, { ErrorAlert } from "../../../../components/shared/Alert"

const OrientationSwitcher = props => {
  const {
    onToggle: onSwitchToggle,
    labelOn = "Using orientation",
    labelOff = "Use orientation",
  } = props

  const { error, requestAccess, revokeAccess } = useDeviceOrientation()

  const [orientationAvailable, setOrientationAvailable] = useState(false)

  const onToggle = toggleState => {
    if (toggleState) {
      requestAccess().then(granted => {
        if (granted) {
          setOrientationAvailable(true)
        } else {
          setOrientationAvailable(false)
        }
      })
    } else {
      revokeAccess().then(() => {
        setOrientationAvailable(false)
      })
    }
    onSwitchToggle(toggleState)
  }
  return (
    <div>
      {/* {requestAccess().then(granted => {
        if (granted) {
          setOrientationAvailable(true)
        } else {
          setOrientationAvailable(false)
        }
      })} */}
      {/* {onToggle(true)} */}
      { <Toggle
        onChange={onToggle}
        isOn={orientationAvailable}
        labelOff={labelOff}
        labelOn={labelOn}
      />
      }
      
    </div>
  )
}

export default OrientationSwitcher
